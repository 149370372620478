.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.scroll-to-top-button.visible {
  opacity: 1;
  visibility: visible;
}

.scroll-to-top-button button {
  background-color: var(--secondary);
  color: var(--primary);
  border: none;
  border-radius: 50%;
  width: 3em;
  height: 3em;
  font-size: 20px;
  cursor: pointer;
}

.scroll-to-top-button button:focus {
  outline: none;
}
