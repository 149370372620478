/* Projects.tsx */
.projects {
  background: var(--primary);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-bottom: 5vh;
}

.projects-sectionTitle {
  text-align: center;
  color: var(--secondary);
  margin: 0.25em;
}

.projects-container {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--secondary);
  padding: 0.75em;
  flex-grow: 1;
  justify-content: space-evenly;
}

/* Project.tsx */
.row {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 2.5vh;
  margin-bottom: 2.5vh;
}

.row Button {
  background-color: transparent;
  border: 1px solid var(--shadowLight);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

hr {
  width: 100%;
  border-color: var(--secondary);
}

.project-text {
  color: var(--white);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.project-title {
  font-weight: bold;
  color: var(--secondary);
  display: flex;
  align-items: center;
  margin: 0;
}

.content {
  color: var(--white);
  text-align: left;
}

.content p {
  font-size: 1.15em;
}

.project-buttons {
  display: flex;
  gap: 10vw;
}

.project-buttons Button {
  padding: 0.5em;
  font-weight: 600;
  color: var(--secondary);
  background-color: transparent;
  border: 1px solid var(--secondary);
  outline: none;
  cursor: pointer;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  transition: all 1s;
  box-shadow: inset 0 0 0 0 var(--secondary);
  width: 50vw;
}

.project-buttons Button:hover {
  color: var(--primary);
  border: 1px solid var(--secondary);
  box-shadow: inset 50vw 0 0 0 var(--secondary);
}
