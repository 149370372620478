.contact {
  align-items: center;
  background: var(--primary);
  display: flex;
  flex-direction: column;
  padding: 3vh;
  min-height: 50vh;
  margin-bottom: 5vh;
}

.contact-title {
  display: flex;
  color: var(--secondary);
  justify-content: center;
}

.contact-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1.5em;
}

.contact-image {
  border: 0.25em solid var(--secondary);
  width: 8em;
  transition: all 1s;
}

.contact-image:hover {
  transform: scale(1.15);
}

.email {
  font-size: 1em;
}

.email-link:link,
.email-link:visited {
  color: var(--secondary);
}

.social-links {
  background-color: var(--secondary);
  border-radius: 1em;
}

.social-links:hover {
  cursor: pointer;
}

.social-links > a > img {
  margin: 0.75em;
}
