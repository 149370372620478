.about {
  display: flex;
  flex-direction: column;
  background-color: var(--primary);
  min-height: 100vh;
  margin-bottom: 5vh;
}

.about-sectionTitle {
  text-align: center;
  color: var(--secondary);
}

.about-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5vh;
}

.about-sectionInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  gap: 2.5vh;
}

.about-image {
  border: 0.25em solid var(--secondary);
  width: 12em;
  transition: all 1s;
}

.about-image:hover {
  transform: scale(1.15);
}

.about-sectionInfo > ul > li {
  list-style-type: square;
  line-height: 1.25;
  font-size: 1.25em;
}

.about-sectionInfo > ul > li > a {
  color: #fff;
  text-decoration: none;
}

.about-sectionInfo > ul > li > a:hover {
  cursor: pointer;
  text-decoration: underline;
}

.about-buttons {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2.5vh;
}

.about-btn {
  font-size: 1.15em;
  font-weight: 700;
  width: 50vw;
  padding: 1em;
  color: var(--secondary);
  background-color: transparent;
  border: 1px solid var(--secondary);
  outline: none;
  cursor: pointer;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  transition: all 1s;
  box-shadow: inset 0 0 0 0 var(--secondary);
}

.about-btn:hover {
  color: var(--primary);
  border: 1px solid var(--secondary);
  box-shadow: inset 50vw 0 0 0 var(--secondary);
}
