:root {
  --primary: #3c3744;
  --secondary: #e7bb41;
  --pink: #ff958c;
  --green: #306b34;
  --shadowLight: #797383;
  --shadowDark: #030304;
  --white: #fff;
}

body {
  margin: 0;
  padding: 0.5em;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary);
  overflow-y: scroll;
}
