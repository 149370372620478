.title-container {
  display: flex;
  flex-direction: column;
  padding: 1em;
  background: var(--primary);
  color: var(--secondary);
  align-items: center;
  gap: 2.5vh;
  min-height: 100vh;
  margin-bottom: 5vh;
}

.title-image {
  border-radius: 50%;
  border: 0.25em solid var(--secondary);
  width: 10em;
  transition: all 1s;
}

.title-image:hover {
  transform: scale(1.2);
}

.title-section {
  gap: 2.5vh;
  display: flex;
  flex-direction: column;
}

.title-sectionTitle {
  font-size: 1.5em;
  text-align: center;
  font-weight: 500;
}

.title-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-btn {
  margin: 1em;
  font-size: 1.25em;
  font-weight: 700;
  width: 50vw;
  padding: 1em;
  color: var(--secondary);
  background-color: transparent;
  border: 1px solid var(--secondary);
  outline: none;
  cursor: pointer;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  transition: all 1s;
  box-shadow: inset 0 0 0 0 var(--secondary);
}

.title-btn:hover {
  color: var(--primary);
  border: 1px solid var(--secondary);
  box-shadow: inset 50vw 0 0 0 var(--secondary);
}
